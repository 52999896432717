/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
import { ref, watch } from '@vue/composition-api'
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function useEnDocumentationUpload() {
  const documentationEnFile = ref('')
  const documentationEnUrl = ref('')
  const fileEnName = ref('')
  const fileLink = ref('')
  const errors = ref([])
  const { errorToast } = useAlertNotification()

  const uploadEnFile = async data => {
    await axiosClient.post('/upload-documentation', data)
      .then(response => {
        fileEnName.value = response.data.pdfFile
        console.log(response.data)
        // successToast('Enregistrement', 'Fichier chargé avec succès')
      })
      .catch(error => {
        errors.value = ''
        errors.value = error.response.data.errors
        const uploadEnFileErrors = error.response.data.errors
        Object.keys(uploadEnFileErrors).forEach(key => {
          errorToast('Oups! Erreur', uploadEnFileErrors[key][0])
        })
      })
  }
  const downloadEnFile = async data => {
    await axiosClient.get(`/download-documentation/${data}`)
      .then(response => {
        console.log(response)
        const blob = new Blob([response.data])
        fileLink.value = window.URL.createObjectURL(blob)
        // successToast('Enregistrement', 'Fichier chargé avec succès')
      })
      .catch(error => {
        errors.value = ''
        errors.value = error.response.data.errors
        const uploadEnFileErrors = error.response.data.errors
        Object.keys(uploadEnFileErrors).forEach(key => {
          errorToast('Oups! Erreur', uploadEnFileErrors[key][0])
        })
      })
  }
  const deleteEnFile = async data => {
    await axiosClient.delete(`/remove-documentation/${data}`)
  }

  const handleDocumentationEnSelected = event => {
    if (event.target.files.length === 0) {
      documentationEnFile.value = ''
      documentationEnUrl.value = ''
      return
    }

    documentationEnFile.value = event.target.files[0]
  }

  watch(documentationEnFile, documentationEnFile => {
    if (!(documentationEnFile instanceof File)) {
      return
    }

    const fileReader = new FileReader()
    console.log(documentationEnFile)
    fileReader.readAsDataURL(documentationEnFile)

    fileReader.addEventListener('load', () => {
      documentationEnUrl.value = fileReader.result
    })
  })

  return {
    documentationEnFile,
    documentationEnUrl,
    handleDocumentationEnSelected,
    uploadEnFile,
    downloadEnFile,
    fileLink,
    fileEnName,
    deleteEnFile,
  }
}
