<template>
  <b-row>

    <!-- <b-col md="3">

    </b-col> -->
    <b-col md="12">
      <b-card
        title="Liste des documentations"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col
              class="text-center"
              md="6"
            >
              <!-- button -->

              <div>
                <b-modal
                  id="modal-prevent-document-closing"
                  ref="my-modal"
                  size="xl"
                  button-size="sm"
                  :title="documentName"
                  ok-only
                  ok-title="Fermer"
                >
                  <iframe
                    :src="documentFile"
                    style="width: 100%;height: 100vh; border: none;"
                  >
                    Problème de chargement du document.
                  </iframe>
                </b-modal>

                <!-- modal -->
                <b-modal
                  id="modal-prevent-closing"
                  ref="my-modal"
                  no-close-on-backdrop
                  button-size="sm"
                  title="Ajouter une documentation"
                  :busy="documentationProcess"
                  ok-title="Ajouter"
                  cancel-title="Fermer"
                  cancel-variant="secondary"
                  ok-variant="success"
                  @ok="handleOk"
                >
                  <validation-observer ref="accountFilesRules">
                    <b-form>
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Libellé"
                            label-for="name"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="libellé"
                              rules="required"
                            >
                              <b-form-input
                                id="name"
                                v-model="form.libelle"
                                :formatter="formatter"
                                placeholder="Libellé"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Catégorie de la documentation"
                            label-for="type"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="catégorie de documentation"
                              rules="required"
                            >
                              <v-select
                                id="type"
                                v-model="form.type"
                                label="libelle"
                                size="sm"
                                :state="errors.length > 0 ? false : null"
                                :options="categorieDocumentations"
                                placeholder="Catégorie de la documentation"
                                :reduce="(categorieDocumentations) => categorieDocumentations.id"
                                class="select-size-sm"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Description"
                            label-for="description"
                          >
                            <b-form-textarea
                              id="description"
                              v-model="form.description"
                              placeholder="Description"
                              rows="3"
                            />
                            <span
                              v-if="errors.description"
                              class="text-danger fs-6"
                              style="font-size:11px"
                              v-text="errors.description[0]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Chargez la version française du document"
                            label-for="fichier"
                            label-size="sm"
                          >
                            <b-form-file
                              id="fichier"
                              ref="file-input"
                              v-model="form.file"
                              accept=".pdf"
                              size="sm"
                              placeholder="Choisir un fichier"
                              @change="handleDocumentationSelected"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Chargez la version anglaise du document"
                            label-for="fichieren"
                            label-size="sm"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Version anglaise"
                              rules="required"
                            >
                              <b-form-file
                                id="fichieren"
                                ref="file-input-en"
                                v-model="form.file1"
                                accept=".pdf"
                                size="sm"
                                placeholder="Choisir un fichier"
                                @change="handleDocumentationEnSelected"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </b-modal>
                <b-modal
                  id="modal-prevent-closing-one"
                  ref="my-modal"
                  no-close-on-backdrop
                  button-size="sm"
                  title="Modifier une documentation"
                  :busy="documentationProcess"
                  ok-title="Modifier"
                  cancel-title="Fermer"
                  cancel-variant="secondary"
                  ok-variant="success"
                  @ok="handleUpdateOk"
                >
                  <validation-observer ref="accountFilesRules">
                    <b-form>
                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Nom de la documentation"
                            label-for="name"
                          >
                            <b-form-input
                              id="name"
                              v-model="form.libelle"
                              :formatter="formatter"
                              placeholder="Nom de la documentation"
                            />
                            <span
                              v-if="errors.libelle"
                              class="text-danger fs-6"
                              style="font-size:11px"
                              v-text="errors.libelle[0]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Catégorie de la documentation"
                            label-for="type"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Type de documentation"
                              rules="required"
                            >
                              <v-select
                                id="type"
                                v-model="form.type"
                                label="libelle"
                                size="sm"
                                :state="errors.length > 0 ? false : null"
                                :options="categorieDocumentations"
                                placeholder="Catégorie de la documentation"
                                :reduce="(categorieDocumentations) => categorieDocumentations.id"
                                class="select-size-sm"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Description"
                            label-for="description"
                          >
                            <b-form-textarea
                              id="description"
                              v-model="form.description"
                              placeholder="Description"
                              rows="3"
                            />
                            <span
                              v-if="errors.description"
                              class="text-danger fs-6"
                              style="font-size:11px"
                              v-text="errors.description[0]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Chargez la version française du document"
                            label-for="fichier"
                            label-size="sm"
                          >
                            <b-form-file
                              id="fichier"
                              ref="file-input"
                              v-model="form.file"
                              accept=".pdf"
                              size="sm"
                              placeholder="Choisir un fichier"
                              @change="handleDocumentationSelected"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Chargez la version anglaise du document"
                            label-for="fichieren"
                            label-size="sm"
                          >
                            <b-form-file
                              id="fichieren"
                              ref="file-input-en"
                              v-model="form.file1"
                              accept=".pdf"
                              size="sm"
                              placeholder="Choisir un fichier"
                              @change="handleDocumentationEnSelected"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </b-modal>
              </div>

            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-prevent-closing
                class="float-left"
                variant="success"
                size="sm"
              >
                Ajouter une documentation
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          :empty-filtered-text="'Aucun résultat n\'a trouvé'"
          :empty-text="'Aucun résultat n\'a trouvé'"
          show-empty
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="data.item.documenten"
                v-b-modal.modal-prevent-document-closing
                @click="sendLink(data.item,'en')"
              >
                <feather-icon icon="EyeIcon" />
                Version Anglaise
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.documentfr"
                v-b-modal.modal-prevent-document-closing
                @click="sendLink(data.item,'fr')"
              >
                <feather-icon icon="EyeIcon" />
                Version Française
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-prevent-closing-one
                @click="getDocumentation(data.item)"
              >
                <feather-icon icon="EditIcon" />
                Modifier
              </b-dropdown-item>
              <b-dropdown-item @click="deleteDoc(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password, ext, mimes,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useDocumentationUpload from '@/services/upload/useDocumentationUpload'
// eslint-disable-next-line import/no-cycle
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput, BForm,
  BFormTextarea, BSpinner, BFormFile, BModal, VBModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useDocumentations from '@/services/admin/documentationService'
// eslint-disable-next-line import/no-cycle
import useTypeDocumentations from '@/services/admin/categorieDocumentationService'
// eslint-disable-next-line import/no-cycle
import useEnDocumentationUpload from '@/services/upload/useEnDocumentationUpload'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BSpinner,
    ValidationObserver,
    BFormFile,
    ValidationProvider,
    BModal,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      password,
      email,
      confirmed,
      ext,
      mimes,
      documentFile: '',
      documentName: '',
    }
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleUpdateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleUpdateModal()
    },
    sendLink(item, lang) {
      // eslint-disable-next-line no-unused-expressions
      lang === 'fr' ? this.documentFile = item.document_fr_url : this.documentFile = item.document_en_url
      this.documentName = item.libelle
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          this.storeDocumentation()
            .then(() => {
              if (this.getStatus()) {
                this.$refs['file-input'].reset()
                this.$refs['file-input-en'].reset()
                // Hide the modal manually
                this.$nextTick(() => {
                  this.$refs['my-modal'].toggle('#toggle-btn')
                })
              }
            })
        }
      })
    },
    handleUpdateModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          this.editDocumentation().then(() => {
            if (this.getStatus()) {
              this.$refs['file-input'].reset()
              this.$refs['file-input-en'].reset()
              // Hide the modal manually
              this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
              })
            }
          })
        }
      })
    },
  },
  setup() {
    const {
      documentations, getDocumentations, translateDocumentation, createDocumentation, deleteDocumentation, errors, documentationProcess, documentationSuccess, loader, documentation, updateDocumentation,
    } = useDocumentations()
    const {
      categorieDocumentationerrors, categorieDocumentation, categorieDocumentations, getTypeDocumentations, categorieDocumentationProcess, createTypeDocumentation, updateTypeDocumentation, getTypeDocumentation, categorieDocumentationloader,
    } = useTypeDocumentations()
    const {
      documentationFile, handleDocumentationSelected, uploadFile, fileName, deleteFile,
    } = useDocumentationUpload()
    const {
      documentationEnFile, handleDocumentationEnSelected, uploadEnFile, fileEnName, deleteEnFile,
    } = useEnDocumentationUpload()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = reactive([
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'type_documentation.libelle', label: 'Catégorie', sortable: true },
      { key: 'actions' },
    ])

    const form = reactive({
      libelle: '',
      description: '',
      slug: '',
      type: '',
      documentfr: '',
      documenten: '',
      file: [],
      file1: [],
    })
    const removeImage = async data => {
      console.clear()
      console.log(data)
      await deleteFile(data)
    }
    // eslint-disable global-require
    const items = documentations

    onMounted(() => {
      getDocumentations()
      getTypeDocumentations()
      // translateDocumentation('Bonjour tout le monde')
      // Set the initial number of items
      totalRows.value = items.length
    })
    const info = (item, index, button) => {
      infoModal.title = `Row index: ${index}`
      infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', infoModal.id, button)
    }

    const resetInfoModal = () => {
      infoModal.title = ''
      infoModal.content = ''
    }
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const sortOptions = computed(() => {
      fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    })

    // Reset Form
    const resetForm = () => {
      form.libelle = null
      form.description = null
      form.slug = null
      form.type = null
      form.documenten = null
      form.documentfr = null
      form.file = []
    }

    // Enregistrer une documentation
    async function storeDocumentation() {
      const result = documentationFile.value instanceof File
      const resulten = documentationEnFile.value instanceof File
      if (result && resulten) {
        const data = new FormData()
        data.append('fichier', documentationFile.value)
        await uploadFile(data)
        data.append('fichier', documentationEnFile.value)
        await uploadEnFile(data)
        form.documenten = fileEnName.value
        form.documentfr = fileName.value
        // await deleteFile(oldImage.value)
      }
      form.documenten = fileEnName
      form.documentfr = fileName
      console.log(form)
      await createDocumentation({
        ...form, type_documentation_id: form.type, documenten: form.documenten, documentfr: form.documentfr,
      })
      if (documentationSuccess.value === true) {
        resetForm()
      }
    }
    function getStatus() {
      return documentationSuccess.value
    }
    // Supprimer une documentation
    const deleteDoc = async data => {
      await deleteDocumentation(data.id).then(() => {
        console.log(documentationSuccess.value)
        if (documentationSuccess.value === true) {
          removeImage(data.document).then(res => { console.log(res).catch(error => console.log(error)) })
        }
      })
    }

    // Obtenir une Documentation par son slug
    const getDocumentation = async item => {
      form.libelle = item.libelle
      form.description = item.description
      form.slug = item.slug
      form.type = item.type_documentation.libelle
      form.documenten = item.documenten
      form.documentfr = item.documentfr
      form.type_documentation_id = item.type_documentation.id
    }

    // Modifier une Documentation
    const editDocumentation = async () => {
      const data = new FormData()
      if (documentationFile.value && documentationEnFile.value) {
        data.append('fichier', documentationFile.value)
        await uploadFile(data)
        data.append('fichier', documentationEnFile.value)
        await uploadEnFile(data)
        form.documentfr = fileName
        form.documenten = fileEnName
      } else if (documentationFile.value) {
        data.append('fichier', documentationFile.value)
        await uploadFile(data)
        form.documentfr = fileName
      } else if (documentationEnFile.value) {
        data.append('fichier', documentationEnFile.value)
        await uploadEnFile(data)
        form.documenten = fileEnName
      }
      await updateDocumentation({ ...form }).then(() => {
        if (documentationSuccess.value === true) {
          resetForm()
        }
      })
    }
    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      info,
      resetInfoModal,
      documentationEnFile,
      handleDocumentationEnSelected,
      uploadEnFile,
      fileEnName,
      deleteEnFile,
      onFiltered,
      sortOptions,
      documentations,
      form,
      errors,
      // validateState,
      formatter,
      documentationProcess,
      documentation,
      deleteDoc,
      getDocumentation,
      editDocumentation,
      loader,
      storeDocumentation,
      getStatus,
      translateDocumentation,
      handleDocumentationSelected,
      categorieDocumentationerrors,
      categorieDocumentation,
      categorieDocumentations,
      getTypeDocumentations,
      categorieDocumentationProcess,
      createTypeDocumentation,
      updateTypeDocumentation,
      getTypeDocumentation,
      categorieDocumentationloader,
      deleteDocumentation,
    }
  },

}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
